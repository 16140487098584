@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.section-bar {
  @include width(1151);
  border-top: 1px solid #435a6e;
  box-sizing: border-box;
  margin: 0 auto;
}
.Work {
  background-color: $navy;
  .mv {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    height: 100vh;
    min-height: 41.66vw;
    max-height: 62.5vw;
    margin-bottom: -7vw;
    .mv-inner {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }
  .main-text {
    @include padding(144,144,143,144);
    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .main-title {
      @include width(756);
      @include font-size(69);
      @include letterSpacing(1.73);
      font-weight: bold;
      color: $sky_blue;
      &_sub {
        @include font-size(12);
        @include lineHeight(20);
        color: #7990a5;
        text-align: right;
      }

    }
    .client_pc {
      @include margin(35,0,58,0);
      @include font-size(12);
      @include letterSpacing(1.2);
      color: #7990a5;
      font-family: 'Inter-Medium';
      display: block;
    }
    .client_sp {
      display: none
    }
    .sub-title {
      @include font-size(21);
      @include width(766);
      @include lineHeight(36);
      @include letterSpacing(2.52);
      color: $sky_blue;
    }
  }
  .work-message {
    @include font-size(14);
    @include margin(33,0,0,0);
    @include width(766);
    @include lineHeight(32);
    @include letterSpacing(1.68);
    color: $sky_blue;
    word-break: break-all;
  }
  
  .work-images {
    @include padding(0,144,144,144);
    li {
      width: 100%;
      &:not(:first-child) {
        @include margin(96,0,0,0);
      }
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
  .other-works {
    @include padding(145,0,0,0);
    background: $sky_blue;
    .slider {
      margin-left: 10%;
    }
    .slick-slide {
      @include margin(70,0,14,0);
      @include height(676);
      margin-right: 3px;
      width: 39.86111vw!important;
      pointer-events: none;
    }
    .slick-active {
      pointer-events: auto;
    }
    .slide-item {
      position: relative;
      width: 100%;
      @include height(676);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .work-name {
      @include font-size(28);
      @include letterSpacing(1.4);
      color: $white;
      font-weight: bold;
      position: absolute;
      bottom: 5%;
      left: 5%;
    }
    .slick-next {
      &::before {
        border-color: #0f2334;
      }
      &::after {
        border-color: #0f2334;
      }
    }
    .slick-prev {
      &::before {
        border-color: #0f2334;
      }
      &::after {
        border-color: #0f2334;
      }
    }
  }
}