.wrapper {
  position: relative;
  & > .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  & > .content {
    z-index: 1;
    position: relative;
  }
}
