@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.people-modal {
  transition: height $transition-duration cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 4;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  visibility: hidden;
  overflow: hidden;
  &_open,
  &_close {
    transform: translateZ(0);
    backface-visibility: hidden;
    visibility: visible;
  }
  &_open {
    height: 100%;
  }
  &_close {
    bottom: auto !important;
    top: 0 !important;
    .modal-inner {
      bottom: auto !important;
      top: 0 !important;
    }
  }
  .modal-inner {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    background: $sky_blue;
  }
  .modal-left {
    width: 50%;
    background-size: cover;
    background-position: center;
  }
  .modal-right {
    width: 50%;
    background: $sky_blue;
    &_inner {
      display: flex;
      align-items: center;
      height: 100%;
      @include margin(-68, 0, 0, 0);
    }
    .introduction {
      @include padding(0, 200, 0, 200);
      margin: 0 auto;
      .name {
        @include font-size(30);
        @include letterSpacing(1.5);
        color: $black;
        font-weight: bold;
      }
      .position {
        @include font-size(18);
        @include margin(14, 0, 0, 0);
        @include letterSpacing(0.9);
        font-weight: bold;
        color: #566878;
      }
      .text {
        @include font-size(14);
        @include margin(46, 0, 0, 0);
        @include lineHeight(32);
        @include letterSpacing(1.68);
        color: $black;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .modal-inner {
      display: block;
      overflow: scroll;
    }
    .modal-left {
      width: 100%;
      height: 360px;
    }
    .modal-right {
      width: 100%;
      padding: 50px 30px;
      .introduction {
        padding: 0 30px;
        .name {
          font-size: 24px;
          letter-spacing: 1.2px;
        }
        .position {
          margin-top: 11px;
          font-size: 12px;
          letter-spacing: 0.6px;
        }
        .text {
          margin-top: 45px;
          font-size: 12px;
          line-height: 28px;
          letter-spacing: 1.44px;
          text-align: justify;
        }
      }
    }
    .modal-close {
      top: 19px;
      right: 51px;
      .menu-bar {
        width: 60px;
        height: 30px;
        .bar {
          width: 60px;
        }
      }
    }
  }
}

.modal-close {
  position: absolute;
  right: 3.33333vw;
  top: 3.33333vw;
  transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 1;
  .menu-bar {
    @include width(96);
    display: inline-block;
    .bar {
      left: 0;
    }
    &:hover {
      transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
      opacity: 0.3;
    }
  }
}
