@import '../common/function.scss';
@import '../common/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  color: $white;
  @include padding(48, 48, 0, 48);
  height: 75px;
  z-index: 3;
  .header-left {
    &_logo {
      position: absolute;
      background: $sky_blue;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      top: 0;
      width: 144px;
      height: 79px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 118px;
      height: auto;
      object-fit: contain;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }
  }
}
