@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.people {
  @include padding(124, 0, 194, 0);
  .preview {
    @include font-size(25);
    @include width(80);
    color: $white;
  }
  .slider {
    @include padding(67, 0, 0, 0);
    margin-left: 10%;
  }
  .slick-initialized .slick-slide {
    @include width(382);
    margin-right: 3px;
  }
  .slide-item {
    position: relative;
    cursor: pointer;
    border-radius: 2px;
    .name {
      @include font-size(18);
      @include letterSpacing(0.9);
      position: absolute;
      top: 5%;
      left: 6%;
      color: #0f2334;
      font-weight: bold;
    }
    .job {
      @include font-size(15);
      @include letterSpacing(0.75);
      position: absolute;
      top: 12%;
      left: 6%;
      color: rgba(15, 35, 52, 0.5);
      font-weight: bold;
    }
  }
  img {
    @include width(382);
    @include height(450);
    object-fit: cover;
    outline: none;
  }
  .slide-last {
    @include width(382);
    @include height(450);
    background: #9daebd;
    position: relative;
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      p {
        @include letterSpacing(1.2);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        font-weight: bold;
        font-family: Inter;
      }
    }
  }
  .slick-arrow {
    top: -2.5%;
  }
  .slick-next {
    &::before {
      border-color: #0f2334;
    }
    &::after {
      border-color: #0f2334;
    }
  }
  .slick-prev {
    &::before {
      border-color: #0f2334;
    }
    &::after {
      border-color: #0f2334;
    }
  }
}
