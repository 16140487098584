@import '../common/function.scss';
@import '../common/variables.scss';

.new_arrival_news {
  @include font-size(16);
  position: fixed;
  bottom: 5%;
  left: 3%;
  z-index: 2;
  color: $sky_blue;
  display: flex;
  opacity: 1;
  transition: opacity 0.5s, color 0.5s;
  cursor: pointer;
  &_hidden {
    opacity: 0;
    transition: opacity 0.5s;
  }
  &_black {
    color: $black;
    transition: color 0.5s;
  }
  .title {
    font-family: Inter;
    font-weight: 300;
  }
  .slide-news {
    @include margin(0, 0, 0, 25);
    white-space: nowrap;
    overflow: hidden;
    width: 0%;
    transition: 1ms;
    transition-delay: 1s;
    &_change {
      width: 100%;
      transition: width 1s ease-out 1s;
    }
    .text {
      opacity: 1;
      &_change {
        opacity: 0;
        transition: 0.5s;
      }
    }
  }
}

.contact-btn {
  @include font-size(18);
  @include padding(0, 20, 0, 20);
  @include width(140);
  @include lineHeight(60);
  position: fixed;
  background-color: $sky_blue;
  color: $black;
  bottom: 5%;
  right: 3%;
  z-index: 2;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  overflow: hidden;
  opacity: 1;
  transition: background-color 0.5s, color 0.5s, opacity 0.5s;
  &_bgblue {
    background-color: $navy;
    color: $sky_blue;
    transition: background-color 0.5s, color 0.5s;
  }
  &_hidden {
    opacity: 0;
    transition: opacity 0.5s;
  }
  .contact-circle {
    @include width(10);
    @include height(10);
    position: relative;
    display: block;
    &::before {
      content: '';
      @include width(10);
      @include height(10);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      opacity: 1;
      background-color: $black;
      transition-property: opacity transform;
      transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1), background-color 0.5s;
    }
    &::after {
      content: '';
      @include width(10);
      @include height(10);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-color: $black;
      opacity: 0;
      transform: scale(0);
      transition-delay: 200ms;
      transition-property: opacity transform;
      transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1), background-color 0.5s;
    }
  }
  &:hover span::before {
    transform: scale(25);
    opacity: 0.5;
    transition-property: opacity transform;
    transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &:hover span::after {
    transform: scale(1);
    opacity: 1;
    transform-origin: 50% 50%;
    transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    transition-property: opacity transform;
    transition-delay: 200ms;
  }
  .contact-circle_white {
    &::before {
      background-color: $sky_blue;
      transition: background-color 0.5s;
    }
    &::after {
      background-color: $sky_blue;
      transition: background-color 0.5s;
    }
  }
}
