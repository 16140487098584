@import './variables';

.loading {
  background: $navy;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 4;
  overflow: hidden;
  transition: height $transition-duration cubic-bezier(0.16, 1, 0.3, 1); //easeOutExpo
  &.close {
    height: 0;
    top: 0;
    bottom: auto;
    & > .inner {
      top: 0;
      bottom: auto;
    }
  }
  & > .inner {
    position: absolute;
    width: 100%;
    height: 100vh;
    bottom: 0;
    .wrapper {
      height: 100%;
      .content {
        height: 100%;
      }
    }
    section {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > span.barBg {
        height: 1px;
        width: 10vw;
        display: block;
        background: rgba(#cfdbe6, 0.2);

        & > .bar {
          height: 1px;
          display: block;
          background: #cfdbe6;
          transition: width 300ms ease-out;
        }
      }
    }
  }
}
