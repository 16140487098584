@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.services {
  @include padding(40,0,168,0);
  .preview {
    color: $sky_blue;
    @include font-size(25);
    @include width(80);
  }
  .slider {
    @include padding(80,0,0,145);
    color: $sky_blue;
    position: relative;
  }

  .slick-track {
    @include margin(0,0,0,-70);
  }

  .slick-initialized .slick-slide {
    @include width(385);
  }
  .slick-slide {
    opacity: 0.4;
    text-align: left;
  }
  .slick-active {
    opacity: 1;
  }
  .item-title {
    @include height(130);
    @include font-size(21);
    @include letterSpacing(3.15);
    line-height: 1.71;
  }
  .item-text {
    @include font-size(14);
    @include width(320);
    @include letterSpacing(1.68);
    display: inline-block;
    line-height: 2.3;
  }

  //slickアイコン位置調整
  .slick-arrow {
    top: -3%;
  }
  .slick-prev {
    left: 83%;
  }
  .slick-next {
    left: 88%;
  }
  .slick-disabled {
    &::before {
      opacity: 0.4;
    }
    &::after {
      opacity: 0.4;
    }
  }
}