@import '../common/function.scss';
@import '../common/variables.scss';

.header-contents {
  position: fixed;
  right: 3.3vw;
  z-index: 2;
}

.menu-bar {
  z-index: 3;
  @include height(30);
  position: relative;
  cursor: pointer;
  .bar {
    @include width(96);
    display: block;
    height: 1px;
    background: none;
    border-top: 1px solid ;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: transform 0.5s, top 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    transition-delay: 0s, 0.5s;
    box-sizing: border-box;
    min-width: 80px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      top: -1.042vw;
      height: 1px;
      border-top: 1px solid;
      transition: transform 0.5s, top 0.5s cubic-bezier(0.16, 1, 0.3, 1);
      transition-delay: 0s, 0.5s;
      box-sizing: border-box;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      top: 0.972vw;
      height: 1px;
      border-top: 1px solid;
      transition: transform 0.5s, top 0.5s cubic-bezier(0.16, 1, 0.3, 1);
      transition-delay: 0s, 0.5s;
      box-sizing: border-box;
    }
    &_skyblue {
      border-color: $sky_blue;
      transition: border-color .5s;
      &::before {
        border-color: $sky_blue;
        transition: border-color .5s;
      }
      &::after {
        border-color: $sky_blue;
        transition: border-color .5s;
      }
    }
    &_black {
      border-color: $black;
      transition: border-color .5s;
      &::before {
        border-color: $black;
        transition: border-color .5s;
      }
      &::after {
        border-color: $black;
        transition: border-color .5s;
      }
    }
    &-open {
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0) !important;
      &::before {
        border-top: 1px solid #0f2334;
        top: 0;
        transform: rotate(-20deg);
        transition: top 0.5s, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
        transition-delay: 0s, 0.5s;
      }
      &::after {
        border-top: 1px solid #0f2334;
        top: 0;
        transform: rotate(20deg);
        transition: top 0.5s, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
        transition-delay: 0s, 0.5s;
      }
    }
  }
}

.header-now {
  margin-top: 30px;
}
.header-now_top {
  @include font-size(12);
  @include padding(9, 0, 9, 0);
  @include width(96);
  min-width: 80px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background: #50687c;
  border-radius: 2px;
  transition: background-color 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  img {
    @include margin(0, 10, 0, 0);
    width: 11px;
    height: 12px;
  }
  &:hover {
    transition: background-color 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    background: #3c5263;
  }
}
.update_day {
  @include font-size(10);
  @include lineHeight(26);
  @include letterSpacing(0.5);
  display: block;
  width: 100%;
  text-align: center;
  &_white {
    color: #FFFFFF;
    transition: color .5s;
  }
  &_black {
    color: $black;
    transition: color .5s;
  }
}
