@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';


.footer {
  @include padding(143,143,144,143);
  margin-top: -1px;
  background: $sky_blue;
  color: $black;
  .over-review {
    @include font-size(12);
    ul {
      @include letterSpacing(0.96);
      line-height: 2.33;
    }
    .privacy {
      @include margin(30,0,0,0);
      a {
        text-decoration: underline;
      }
    }
    .links {
      @include margin(30,0,0,0);
      p {
        @include letterSpacing(0.96);
        display: inline-block;
        text-decoration: underline;
        span {
          margin: 0 10px;
          display: inline-block;
        }
        &:hover {
          color: #50687c;
        }
      }
    }

  }
  
  &-logo {
    img {
      @include width(157);
    }
    span {
      @include margin(103,0,0,0);
      @include font-size(12);
      display: block;
      text-align: right;
    }
  }
}