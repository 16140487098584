@import './function.scss';
@import './variables.scss';

html {
  overflow:auto;
}

body {
  font-family: 'Inter', 'ゴシックMB101', sans-serif;
  font-feature-settings: "palt";
}

.db {
  font-family: "ゴシックMB101 DB";
  }
  
.r {
  font-family: "ゴシックMB101 R";
}
.i-b {
  font-family: 'Inter-Bold';
}
.i-l {
  font-family: 'Inter-Ligth';
}

@mixin g-db {
  font-family: "ゴシックMB101 DB";
}

@mixin g-r {
  font-family: "ゴシックMB101 R";
}


.no-scroll {
  overflow: hidden;
}

.sp_only {
  display: none
}

.section-padding {
  @include padding(0,143,0,143);
}

.section-width {
  @include width(1140);
}

.bg-skyblue {
  background-color: $sky_blue;
}

// color
.f-sky_blue {
  color: $sky_blue;
}
.f-black {
  color: $black;
}

//セクション毎のボーダー
.horizontal-bar {
  @include width(1151);
  border-top: 1px solid #435a6e;
  box-sizing: border-box;
  margin: 0 auto;
}

.horizontal-bar_skyblue {
  @include width(1151);
  border-top: solid 1px #adbecc;
  box-sizing: border-box;
  margin: 0 auto;
}

//フレックスボックス
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.content-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

//タイトルのスタイル
.base-title {
  @include font-size(28);
  @include letterSpacing(0.7);
  font-family: 'Inter';
  font-weight: 300;
  text-align: left;
  position: relative;
}


// Contents Peopleセクションのスリックのアイコンのスタイル
.slick-arrow {
  position: absolute;
  top: -2%;
}
.slick-next {
  left: 86.5%;
  width: 43px;
  &::before {
    content: "";
    position: absolute;
    opacity: 1;
    top: 3px;
    display: block;
    border-top: 1px solid #7990a5;
    width: 34px;
    transform: skewY(19deg);
  }
  &::after {
    content: "";
    position: absolute;
    opacity: 1;
    top: 15px;
    display: block;
    border-top: 1px solid #7990a5;
    width: 34px;
    transform: skewY(-19deg);
    transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &:hover {
    transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    opacity: 0.3;
  }
}
.slick-prev {
  left: 81%;
  width: 43px;
  &::before {
    content: "";
    position: absolute;
    top: 3px;
    opacity: 1;
    display: block;
    border-top: 1px solid #7990a5;
    width: 34px;
    transform: skewY(-19deg);
  }
  &::after {
    content: "";
    position: absolute;
    top: 15px;
    display: block;
    opacity: 1;
    border-top: 1px solid #7990a5;
    width: 34px;
    transform: skewY(19deg);
    opacity: 1;
    transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &:hover {
    transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    opacity: 0.3;
  }
}
.slick-disabled {
  pointer-events: none;
  &::before {
    opacity: 0.5!important;
  }
  &::after {
    opacity: 0.5!important;
  }
}

.view-all {
  @include font-size(18);
  @include lineHeight(25);
  @include letterSpacing(0.2);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25%;
  font-weight: 900;
  color: #0f2334;
  font-family: Inter;
  -webkit-text-stroke: 0.2px #0f2334;
  display: flex;
  align-items: center;;
  span {
    content: "";
    @include width(10);
    @include height(10);
    @include margin(0,0,0,10);
    display: block;
    width: 10px;
    height: 10px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0%;
      width: 10px;
      height: 10px;
      opacity: 1;
      border-radius: 50%;
      background: $black;
      transition: 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $black;
      transform: scale(0);
      opacity: 0;
      transition: .5s cubic-bezier(0.16, 1, 0.3, 1);
      transition-delay: 200ms;
    }
  }
  &:hover span::before{
    transform: scale(18);
    transform-origin: 50% 50%;
    background: #adbdcc;
    opacity: 0;
    transition: 1s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &:hover span::after{
    opacity: 1;
    transform: scale(1);
    transition: .5s cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 2;
    transition-delay: 200ms;
  }
}

// hover時のスタイル
.img-hover {
  overflow: hidden;
  img {
    transition: transform 0.3s ease-out;
    transform: scale(1);
    &:hover {
      transition: transform 0.3s ease-out;
      transform: scale(1.1);
    }
  }
  .no-scale {
    transform: scale(1)!important;
  }
}

.hover-opacity {
  transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 1;
  &:hover {
    transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    opacity: 0.3;
  }
}