@import '../common/function.scss';
@import '../common/variables.scss';


.cursor {
  font-size: 12px;
  height: 120px;
  width: 120px;
  color: $sky_blue;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $sky_blue;
  position: fixed;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s cubic-bezier(0.65, 0, 0.35, 1);
  opacity: 0;
  &-active {
    transition: opacity 0.25s cubic-bezier(0.65, 0, 0.35, 1), transform 1s cubic-bezier(0.33, 1, 0.68, 1);
    opacity: 1;
  }
}