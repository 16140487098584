@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.news {
  @include padding(124,0,104,0);
  background-color: #9daebd;
  .slider {
    @include padding(50,0,0,0);
    margin-left: 10%;
  }
  .slick-initialized .slick-slide  {
    @include width(382);
    margin-right: 3px;
  }
  .img-inner {
    @include height(236);
    border-radius: 2px
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .news-date {
    @include margin(36,0,0,0);
    @include font-size(12);
    @include letterSpacing(0.96);
    color: #566878;
    font-weight: 500;
  }
  .news-title {
    @include font-size(21);
    @include width(345);
    @include margin(12,0,0,0);
    @include letterSpacing(1.68);
    color: $black;
    line-height: 1.7;
  }
  .slick-next {
    &::before {
      border-color: #0f2334;
    }
    &::after {
      border-color: #0f2334;
    }
  }
  .slick-prev {
    &::before {
      border-color: #0f2334;
    }
    &::after {
      border-color: #0f2334;
    }
  }
}

