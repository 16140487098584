@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.solution {
  @include padding(40,143,116,143);
  color: $white;
}
.solution-inner {
  display: flex;
  align-items: center;
  .left-contents {
    @include width(300);
    @include font-size(14);
    @include lineHeight(32);
    @include letterSpacing(1.68);
    word-break: break-all;
    color: $sky_blue;
    text-align: justify;
    .sdgs_link {
      @include width(300);
      @include padding(0,25,0,22);
      @include font-size(18);
      @include margin(40,0,0,0);
      @include lineHeight(60);
      @include letterSpacing(2.16);
      overflow: hidden;
      font-family: Inter;
      color: $sky_blue;
      font-weight: bold;
      border-radius: 2px;
      background: #50687c;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      p {
        @include font-size(15);
        @include letterSpacing(1.8);
        display: inline;
      }
      span {
        display: block;
        @include width(10);
        @include height(10);
        position: relative;
        &::before {
          content: "";
          @include width(10);
          @include height(10);
          position: absolute;
          border-radius: 50%;
          display: block;
          background: #cfdbe6;
          opacity: 1;
          transform: scale(1);
          transition: .5s cubic-bezier(0.16, 1, 0.3, 1);
        }
        &::after {
          content: "";
          @include width(10);
          @include height(10);
          position: absolute;
          border-radius: 50%;
          background: #cfdbe6;
          transform: scale(0);
          opacity: 0;
          transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
          transition-delay: 200ms;
        }
      }
      &:hover span::before {
        transition: 1s cubic-bezier(0.16, 1, 0.3, 1);
        transform: scale(60);
        background: #3c5263;
        opacity: 0.5;
      }
      &:hover span::after {
        transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition-delay: 200ms;
      }
    }
  }
  .right-contents {
    @include margin(0,0,0,86);
    position: relative;
    span {
      @include font-size(18);
      color: $sky_blue;
      font-weight: bold;
    }
    //右中央の矢印
    .arrow {
      @include width(650);
      opacity: 0;
      position: absolute;
      right: -8%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
      &_active {
        transition: opacity 2s cubic-bezier(0, 0.55, 0.45, 1);
        opacity: 0.8;
      }
    }
    .arrow-l {
      height: 1px;
      border-top: 1px solid rgba(255, 254, 255, 0.4);
      z-index: -1;
      width: 10px;
      &_active {
        width: 100%;
        transition: width 2s cubic-bezier(0, 0.55, 0.45, 1);
        transition-delay: .3s;
      }
    }
    .arrow-r {
      position: relative;
      display: inline-block;
      color: $white;
      text-decoration: none;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 12px solid rgba(255, 254, 255, 0.4);
    }
  }
}
//円形のスタイル
.circle {
  @include margin(0,35,0,0);
  border: 1px solid rgba(255, 254, 255, 0.4);
  border-radius: 50%;
  transform-origin: left center;
  &-l {
    @include width(720);
    @include height(720);
    @include padding(0,50,0,0);
    line-height: 2;
    opacity: 0;
    transform: scale(0);
    &_active {
      transition: opacity .5s cubic-bezier(0, 0.55, 0.45, 1), transform .5s cubic-bezier(0, 0.55, 0.45, 1);
      transition-delay: 1.2s;
      opacity: 1;
      transform: scale(1);
      transform-origin: left center;
    }
    &_text {
      @include lineHeight(32);
      @include letterSpacing(1.44);
      position: absolute;
      top: 49.7%;
      left: 63%;
      transform: translateY(-50%);
      opacity: 0;
      white-space: nowrap;
      &_active {
        transition: opacity .5s cubic-bezier(0, 0.55, 0.45, 1);
        transition-delay: 1.5s;
        opacity: 1;
      }
    }
  }
  &-m {
    @include width(456);
    @include height(456);
    @include padding(0,50,0,0);
    position: absolute;
    top: 17%;
    transform: translateY(-50%);
    opacity: 0;
    transform: scale(0);
    &_active {
      transition: opacity .5s cubic-bezier(0, 0.55, 0.45, 1), transform .5s cubic-bezier(0, 0.55, 0.45, 1);
      transition-delay: .6s;
      transform-origin: left center;
      opacity: 1;
      transform: scale(1);
    }
    &_text {
      position: absolute;
      top: 50%;
      left: 33%;
      transform: translateY(-50%);
      opacity: 0;
      &_active {
        transition: opacity .5s cubic-bezier(0, 0.55, 0.45, 1);
        transition-delay: .9s;
        opacity: 1;
      }
    }
  }
  &-s {
    @include width(191);
    @include height(191);
    position: absolute;
    top: 36%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: scale(0);
    &_active {
      transition: opacity .5s cubic-bezier(0, 0.55, 0.45, 1), transform .5s cubic-bezier(0, 0.55, 0.45, 1);
      transform: scale(1);
      transform-origin: left center;
      opacity: 1;
    }
    &_text{
      position: absolute;
      top: 50%;
      left: 6%;
      transform: translateY(-50%);
      opacity: 0;
      &_active {
        transition: opacity .5s cubic-bezier(0, 0.55, 0.45, 1);
        transition-delay: .3s;
        opacity: 1;
      }
    }
  }
}