@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.page-header {
  .page-header__title {
    @include g-db;
    @include font-size(32);
    @include lineHeight(54);
    @include letterSpacing(3.2);
    font-weight: bold;
    color: #0f2334;
  }
  
  .page-header__description {
    @include g-db;
    color: #0f2334;
    @include font-size(13);
    @include letterSpacing(1.56);
    line-height: 2.31;
    font-weight: bold;
    text-align: justify;
  }

  .page-header__date  {
    color: #50687c;
    @include font-size(13);
    @include letterSpacing(1.04);
    line-height: 1;
    font-weight: 500;
  }
}

.Blog,
.BlogDetail {
  background: $sky_blue;

  .slash {
    font-weight: 300;
  }

  .category-list {
    display: flex;
    align-items: flex-start;
    border-bottom: solid 1px #adbecc;
    @include padding(0, 100, 24, 0);

    .category-list__title {
      @include g-db;
      position: relative;
      top: get_vw(2);
      color: #0f2334;
      @include font-size(16);
      letter-spacing: get_vw(2.4);
      font-weight: bold;
      flex-shrink: 0;
      @include width(65 + 317 + 3);
    }
  
    .category-list__items {
      display: flex;
      flex-wrap: wrap;
      gap: get_vw(24) get_vw(42);
    }
    
    .category-item {
      display: flex;
      align-items: center;
      color: #0f2334;
      line-height: 1;
      @include font-size(18);
      font-weight: 600;
      letter-spacing: get_vw(0.9);
  
      .icon {
        display: none;
        width: get_vw(18);
        height: get_vw(18);
        margin-right: get_vw(5);
        background-size: contain;
      }
  
      &.selected {
        color: #7990a5;
  
        .icon {
          display: block;
        }
      }
    }
  }
}

.Blog {
  .page {
    @include padding(187, 144, 0, 144);
  }

  .category-list {
    @include margin(0, 0, 144, 0);
  }

  &--archive {
    .category-list {
      @include margin(0, 0, 100, 0);
    }
  }

  .page-header {
    @include height(958);
    margin-bottom: get-vw(144);
    display: flex;
    justify-content: space-between;
    
    .page-header__text {
      @include padding(162, 63, 0, 0);
    }

    .page-header__date  {
      @include margin(20, 0, 20, 0);

      .label {
        display: none;
      }
    }

    .page-header__image {
      @include width(766);
      flex-shrink: 0;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--empty {
        border: solid 1px #b6c1cc;
        background: linear-gradient(-51.3694deg, transparent 49.9%, #b6c1cc 49.9%, #b6c1cc 50.0%, transparent 50.0%, transparent);
      }
    }

    .page-header__description {
      @include width(289);
      @include margin(50, 0, 0, 0);
    }

    .read-btn {
      @include font-size(18);
      @include padding(0, 20, 0, 20);
      @include width(289);
      @include lineHeight(60);
      background-color: $sky_blue;
      color: $black;
      bottom: 5%;
      right: 3%;
      z-index: 2;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      overflow: hidden;
      opacity: 1;
      margin-top: 36px;
      transition: background-color 0.5s, color 0.5s, opacity 0.5s;

      &_bgblue {
        background-color: $navy;
        color: $sky_blue;
        transition: background-color 0.5s, color 0.5s;
      }
      &_hidden {
        opacity: 0;
        transition: opacity 0.5s;
      }
      .read-circle {
        @include width(10);
        @include height(10);
        position: relative;
        display: block;
        &::before {
          content: '';
          @include width(10);
          @include height(10);
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 50%;
          opacity: 1;
          background-color: $black;
          transition-property: opacity transform;
          transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1), background-color 0.5s;
        }
        &::after {
          content: '';
          @include width(10);
          @include height(10);
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 50%;
          background-color: $black;
          opacity: 0;
          transform: scale(0);
          transition-delay: 200ms;
          transition-property: opacity transform;
          transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1), background-color 0.5s;
        }
      }
      .read-circle_white {
        &::before {
          background-color: $sky_blue;
          transition: background-color 0.5s;
        }
        &::after {
          background-color: $sky_blue;
          transition: background-color 0.5s;
        }
      }
    }
  }
  
  .items {
    @include padding(0, 0, 146, 0);
    display: grid;
    grid-template-columns: get-vw(382) get-vw(382) get-vw(382);
    gap: 3px;
    row-gap: get-vw(100);
  }

  .item {
    .item__image {
      height: get-vw(478);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--empty {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #b6c1cc;
        background: linear-gradient(-51.3694deg, transparent 49.9%, #b6c1cc 49.9%, #b6c1cc 50.1%, transparent 50.1%, transparent);
      }
    }

    .item__body {
      padding: get-vw(30) 1em 0 0;
    }
    
    .item__date {
      @include font-size(13);
      color: #566878;
    }
    
    .item__title {
      @include g-db;
      @include font-size(21);
      margin-top: get-vw(20);
      font-weight: bold;
      line-height: 1.71;
      color: #0f2334;
    }
  }
  
  .pagination {
    @include margin(0, 0, 220, 0);
    position: relative;
    display: flex;
    gap: 0 22px;
    justify-content: center;
    border-top: 1px solid #a3abb1;
    padding-top: get-vw(30);
    
    .page-numbers {
      text-align: center;
      @include font-size(18);
      font-weight: bold;
    }
    
    .current {
      color: #adbecc;
    }

    .prev,
    .next {
      @include font-size(18);
      position: absolute;
      font-weight: bold;

      &::before {
        content: "";
        @include width(10);
        @include height(10);
        top: 0;
        bottom: 0;
        margin: auto 0;
        position: absolute;
        border-radius: 50%;
        background: #0f2334;
      }
    }
    
    .prev {
      left: 0;
      text-align: left;
      padding-left: 1.2em;

      &::before {
        left: 0;
      }
    }

    .next {
      right: 0;
      text-align: right;
      padding-right: 1.2em;

      &::before {
        right: 0;
      }
    }
  }
}

.BlogDetail {
  .page-header {
    @include height(900);
    display: flex;
    justify-content: space-between;
    font-size: 32px;

    .page-header__text {
      @include padding(255, 195, 0, 195);
    }

    .page-header__description {
      @include margin(35, 0, 0, 0);
    }
  
    .page-header__image {
      @include width(720);
      height: 100%;
      flex-shrink: 0;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--empty {
        border: solid 1px #b6c1cc;
        background: linear-gradient(-51.3694deg, transparent 49.9%, #b6c1cc 49.9%, #b6c1cc 50.0%, transparent 50.0%, transparent);
      }
    }

    .page-header__date  {
      @include margin(40, 0, 0, 0);
    }
  }

  .side-menu {
    @include margin(52, 48, 0, 0);
    text-align: right;
    position: sticky;
    top: get_vw(250);
    width: 20px;
    margin-left: auto;
  
    .text {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-align: left;
      display: inline-block;
      margin-bottom: 48px;
      @include font-size(13);
      color: #50687c;
      font-weight: 500;
      letter-spacing: 1.04px
    }
    .icon {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      justify-content:flex-end;
    }
    .icon-tw{
      margin-bottom: 25px;
      width: 20px;
      height: 16px;
    }
    .icon-fb{
      margin-bottom: 25px;
      width: 20px;
      height: 19px;
      text-align: center;
    }
    .icon-ln{
      width: 20px;
      height: 19px;
    }
  }

  .page__content {
    @include g-r;
    @include margin(-250, 0, 0, 0);
    @include padding(194, 380, 217, 380);
    color: #0f2334;

    // start margin -------------------------
    > * {
      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    h2 {
      margin-bottom: get_vw(108);
    }

    h2 + h2 {
      margin-top: get_vw(144 - 108);
    }

    h2 + h3 {
      margin-top: 0;
    }

    h3 {
      margin-bottom: get_vw(30);
    }

    h3 + h3 {
      margin-top: get_vw(144 - 48);
    }

    h3 + h2 {
      margin-top: get_vw(144 - 48);
    }

    p,
    ol,
    ul,
    figcaption,
    .wp-block-quote,
    .link-card,
    .wp-block-embed,
    .wp-block-gallery img,
    .wp-block-image img {
      margin-bottom: get_vw(48);
    }

    p + h2,
    ul + h2,
    ol + h2,
    figcaption + h2,
    .wp-block-quote + h2,
    .link-card + h2,
    .wp-block-embed + h2,
    .wp-block-gallery img + h2,
    .wp-block-image img + h2 {
      margin-top: get_vw(144 - 48);
    }

    p + h3,
    ul + h3,
    ol + h3,
    figcaption + h3,
    .wp-block-quote + h3,
    .link-card + h3,
    .wp-block-embed + h3,
    .wp-block-gallery img + h3,
    .wp-block-image img + h3 {
      // margin-top: get_vw(144 - 48);
      margin-top: get_vw(108);
    }
    

    .wp-block-quote {
      p,
      ul,
      ol {
        margin-bottom: 0;
      }
    }
    // end margin -------------------------


    h2 {
      @include g-db;
      @include font-size(32);
      @include lineHeight(48);
      @include letterSpacing(4.05);
      display: inline-block;
      font-weight: bold;
      text-align: left;
      width: auto;
      text-align: left;
    }

    h3 {
      @include g-db;
      @include font-size(24);
      @include lineHeight(36);
      @include letterSpacing(2.88);
      font-weight: bold;
    }

    p,
    li {
      @include font-size(18);
      @include letterSpacing(1.56);
      line-height: 2;
      text-align: justify;
    }

    ul {
      padding-left: 1em;
    }

    ol {
      padding-left: 1.4em;
    }

    li {
      list-style: disc;

      &:not(:first-of-type) {
        margin-top: 1em;
      }
    }

    ol li {
      list-style: decimal;
    }

    figcaption {
      @include font-size(13);
      @include lineHeight(26);
      @include letterSpacing(1.04);
      color: #50687c;
    }

    .wp-block-quote {
      p {
        margin-bottom: 0 !important;
      }
    }

    .link-card {
      @include height(157);
      display: block;
      text-decoration: none;
      background-color: #dde6ee;

      .link-card__inner {
        display: flex;
        justify-content: space-between;
      }
  
      .link-card__body {
        @include padding(34, 36, 30, 36);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
  
      .link-card__title {
        @include font-size(18);
        font-weight: bold;
        margin-bottom: 0;
        line-height: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
  
      .link-card__image {
        @include width(157);
        @include height(157);
        display: block;
        object-fit: cover;
      }
  
      .link-card__host {
        @include font-size(12);
        color: #0f2334;
        line-height: 2.67;
        margin-bottom: 0;
      }
    }

    .wp-block-gallery,
    .wp-block-image {
      margin: 0;
    }
    
    .wp-block-gallery img,
    .wp-block-image img {
      width: 100%;
      height: auto;
    }
    
    .wp-block-quote {
      border-left: 6px solid #bdcbd8;
      padding-left: 30px;
    }

    .wp-block-embed {
      position: relative;

      .twitter-tweet {
        max-width: 100% !important;
      }

      &.is-type-video {
        .wp-block-embed__wrapper {
          padding-bottom: 56.25%; // アスペクト比 16:9の場合の縦幅
          // height: 0;
          overflow: hidden;

          blockquote.tiktok-embed {
            margin-bottom: -56.25%; 
            iframe {
                position: relative !important;
            }
          } 

          iframe {
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}