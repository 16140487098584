@media screen and (max-width: 767px) {
  @import '../top/layout_sp.scss';
  @import '../work/layout_sp.scss';
  @import '../people/layout_sp.scss';
  @import '../sdgs/layout_sp.scss';
  @import '../blog/layout_sp.scss';
}

@media screen and (min-width: 768px) {
  @import '../top/layout_pc.scss';
  @import '../work/layout_pc.scss';
  @import '../people/layout_pc.scss';
  @import '../sdgs/layout_pc.scss';
  @import '../blog/layout_pc.scss';
}

@import './transition';
