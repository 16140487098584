@import '../common/function.scss';
@import '../common/util.scss';
@import '../common/variables.scss';

.Sdgs {
  background: $navy;
  color: $sky_blue;
  .border {
    @include width(1151);
    margin: 0 auto;
    border-top: 1px solid #435a6e;
  }
  .first-view {
    @include padding(150, 148, 191, 148);
    h1 {
      @include font-size(27);
      @include letterSpacing(3.24);
      strong {
        line-height: 0.85;
        .strong {
          @include font-size(69);
          @include letterSpacing(4.14);
          font-weight: bold;
          font-family: Inter;
        }
        .span {
          font-family: Inter;
          font-weight: 300
        }
      }
    }
    .heading {
      @include margin(88,0,0,0);
      @include font-size(21);
      @include letterSpacing(2.52);
    }
    .text {
      @include margin(24,0,0,0);
      @include font-size(14);
      @include lineHeight(32);
      @include letterSpacing(1.68);
    }
  }
  .sdgs-contents {
    @include padding(0,145,85,145);
    .contents-block {
      @include padding(43,0,144,0);
      .num {
        @include font-size(28);
        font-weight: 300;
      }
    }
    .content-inner {
      @include padding(50,0,0,383);
      h2 {
        @include font-size(27);
        @include lineHeight(48);
        @include letterSpacing(3.24);
        span {
          font-family: Inter;
          font-weight: bold
        }
      }
      .description {
        @include margin(49,0,0,0);
        @include font-size(14);
        @include lineHeight(32);
        @include letterSpacing(1.68);
      }
      .action-list {
        @include margin(50,0,0,0);
        @include font-size(21);
        @include lineHeight(48);
        @include letterSpacing(2.52);
        span {
          @include font-size(14);
          @include letterSpacing(1.68);
        }
        li {
          &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 9px;
            height: 3px;
            margin-right: 14px;
            background: $sky_blue;
          }
        }
      }
    }
  }
}