@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

$base-width: 375;

.page-header {
  .page-header__title {
    @include g-db;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 3px;
    font-weight: bold;
    color: #0f2334;
  }
  
  .page-header__description {
    @include g-db;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 1.8px;
    font-weight: bold;
    color: #0f2334;
    text-align: justify;
  }
}

.Blog,
.BlogDetail {
  background: $sky_blue;

  .slash {
    font-weight: 300;
  }

  .category-list {
    @include padding(0, 100, 24, 0);

    .category-list__title {
      color: rgb(80, 104, 124);
      font-size: 15px;
      letter-spacing: 2.4px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  
    .category-list__items {
      display: flex;
      flex-wrap: wrap;
      gap: 22px 25px;
    }
    
    .category-item {
      display: flex;
      align-items: center;
      color: #0f2334;
      line-height: 1;
      font-size: 16px;
      font-weight: 600;
  
      .icon {
        display: none;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        background-size: contain;
      }
  
      &.selected {
        color: #7990a5;
  
        .icon {
          display: block;
        }
      }
    }
  }
}

.Blog {
  .page {
    padding: get-vw(151, $base-width) 0 0 get-vw(30, $base-width);

    &--index {
      padding-top: get-vw(136, $base-width);
    }
  }

  .category-list {
    margin-bottom: 60px;
  }

  &--archive {
    .category-list {
      margin-top: -15px;
    }
  }

  .page-header {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    font-size: 32px;
    width: 100%;
    border-top-color: $sky_blue;
    margin-bottom: get-vw(75, $base-width);

    .page-header__title {
      margin-bottom: get-vw(20, $base-width);
    }

    .page-header__text {
      padding: get-vw(32, $base-width) get-vw(30, $base-width) 0 get-vw(60, $base-width);
    }
  
    .page-header__date  {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 0;
      left: -6px;
      writing-mode: vertical-rl;
      font-size: 11px;
      color: #50687c;
      font-weight: bold;
      letter-spacing: 0.64px;

      .label {
        display: flex;
        align-items: center;

        &::after {
          display: block;
          content: '';
          width: 1.5px;
          height: 26.5px;
          margin: 10px 0;
          background-color: rgba(#566878, 0.5);
        }
      }
    }
  
    .page-header__image {
      position: relative;
      margin: 0 0 0 auto;
      flex-shrink: 0;
      width: get-vw(315, $base-width);
      height: 395px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--empty {
        border: solid 1px #b6c1cc;
        border-right: none;
        background: linear-gradient(-51.3694deg, transparent 49.9%, #b6c1cc 49.9%, #b6c1cc 50.1%, transparent 50.1%, transparent);
      }
    }
  }

  .items {
    display: grid;
    grid-template-columns: get-vw(171, $base-width) get-vw(171, $base-width);
    gap: 3px;
    row-gap: get-vw(60, $base-width);
    padding-bottom: get-vw(80, $base-width);
  }

  .item {
    .item__body {
      padding-top: 19px;
      padding-right: 1em;
    }

    .item__image {
      width: 100%;
      height: get-vw(214, $base-width);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--empty {
        border: solid 1px #b6c1cc;
        background: linear-gradient(-51.3728deg, transparent 49.9%, #b6c1cc 49.9%, #b6c1cc 50.1%, transparent 50.1%, transparent); /* 右上がりの斜線 */
      }
    }
    
    .item__date {
      font-size: 11px;
      color: #566878;
    }
    
    .item__title {
      font-size: 15px;
      line-height: 24px;
      margin-top: 8px;
      font-weight: bold;
      color: #0f2334;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    gap: 0 get-vw(20, $base-width);
    border-top: 1px solid #a3abb1;
    padding: get-vw(20, $base-width) get-vw(30, $base-width) 0 0;
    margin-bottom: get-vw(80, $base-width);
  
    .page-numbers {
      font-size: 16px;
      font-weight: bold;
    }
    
    .current {
      color: #adbecc;
    }
    
    .prev,
    .next {
      font-size: 16px;
      position: absolute;
      font-weight: bold;

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        position: absolute;
        border-radius: 50%;
        background: #0f2334;
      }
    }
    
    .prev {
      left: get-vw(30, $base-width);
      text-align: left;
      padding-left: 1.2em;

      &::before {
        left: 0;
      }
    }

    .next {
      right: get-vw(30, $base-width);
      text-align: right;
      padding-right: 1.2em;

      &::before {
        right: 0;
      }
    }
  }  
}

.BlogDetail {
  .page-header {
    display: flex;
    flex-direction: column-reverse;

    .page-header__description {
      margin-top: 17px;
    }

    .page-header__text {
      position: relative;
      padding: get-vw(36, $base-width) get-vw(75, $base-width) get-vw(26, $base-width) get-vw(30, $base-width);
    }
  
    .page-header__date  {
      position: absolute;
      top: get-vw(43, $base-width);
      right: get-vw(30, $base-width);
      writing-mode: vertical-rl;
      font-size: 11px;
      color: rgb(86, 104, 120);
      font-weight: bold;
      letter-spacing: 1.04px;
    }
  
    .page-header__image {
      flex-shrink: 0;
      width: 100%;
      height: 469px;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      &--empty {
        border: solid 1px #b6c1cc;
        background: linear-gradient(-51.3694deg, transparent 49.9%, #b6c1cc 49.9%, #b6c1cc 50.1%, transparent 50.1%, transparent);
      }
    }
  }

  .side-menu {
    padding: 0 get-vw(30, $base-width);
    display: flex;
    align-items: center;
  
    .text {
      text-align: left;
      font-size: 11px;
      color: rgb(80, 104, 124);
      font-weight: bold;
      letter-spacing: 0.88px;
    }
    .icon {
      gap: 24px;
      display: flex;
      text-align: center;
      margin-left: 20px;
    }
  
    .icon-tw,
    .icon-fb,
    .icon-ln {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    .icon-tw {
      width: 20px;
      height: 16px;
    }
    .icon-fb {
      width: 10px;
      height: 20px;
    }
    
    .icon-ln{
      width: 20px;
      height: 19px;
    }
  }

  .page__content {
    @include g-r;
    padding: get-vw(92, $base-width) get-vw(30, $base-width) get-vw(40, $base-width);
    color: #0f2334;

    // start margin -------------------------
    > * {
      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    h2 {
      margin-bottom: 60px;
    }

    h2 + h2 {
      margin-top: 80px - 60px;
    }

    h2 + h3 {
      margin-top: 0;
    }

    h3 + h3 {
      margin-top: 60px - 24px;
    }

    h3 + h2 {
      margin-top: 80px - 24px;
    }

    h3,
    p,
    ol,
    ul,
    figcaption,
    .wp-block-quote,
    .link-card,
    .wp-block-embed,
    .wp-block-gallery img,
    .wp-block-image img {
      margin-bottom: 24px;
    }

    p + h2,
    ul + h2,
    ol + h2,
    figcaption + h2,
    .wp-block-quote + h2,
    .link-card + h2,
    .wp-block-embed + h2,
    .wp-block-gallery img + h2,
    .wp-block-image img + h2 {
      margin-top: 80px - 24px;
    }

    p + h3,
    ul + h3,
    ol + h3,
    figcaption + h3,
    .wp-block-quote + h3,
    .link-card + h3,
    .wp-block-embed + h3,
    .wp-block-gallery img + h3,
    .wp-block-image img + h3 {
      margin-top: 60px - 24px;
    }
    

    .wp-block-quote {
      p,
      ul,
      ol {
        margin-bottom: 0 !important;
      }
    }
    // end margin -------------------------


    h2 {
      @include g-db;
      font-size: 17px;
      line-height: 30px;
      letter-spacing: 2.55px;
      font-weight: bold;
      text-align: left;

    }

    h3 {
      @include g-db;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 2.25px;
      font-weight: bold;
    }

    p,
    li {
      font-size: 13px;
      line-height: 26px;
      letter-spacing: 1.04px;
      text-align: justify;
    }

    ul {
      padding-left: 1em;
    }

    ol {
      padding-left: 1.4em;
    }

    li {
      list-style: disc;

      &:not(:first-of-type) {
        margin-top: 1em;
      }
    }

    ol li {
      list-style: decimal;
    }

    figcaption {
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 0.88px;
      color: rgb(80, 104, 124);
    }

    .link-card {
      background-color: rgb(221, 230, 238);
      display: block;
      text-decoration: none;

      .link-card__inner {
        display: flex;
        flex-direction: column-reverse;
      }
  
      .link-card__body {
        padding: 29px 30px 27px;
      }
  
      .link-card__title {
        font-size: 13px;
        line-height: 2;
        font-weight: bold;
        margin-bottom: 0;
      }
  
      .link-card__image {
        object-fit: cover;
        width: 100%;
        height: auto;
        order: 1;
      }
  
      .link-card__host {
        margin-top: 20px;
        font-size: 11px;
        margin-bottom: 0;
      }
    }

    .wp-block-gallery,
    .wp-block-image {
      margin: 0;
    }
    
    .wp-block-gallery img,
    .wp-block-image img {
      width: 100%;
      height: auto;
    }
    
    .wp-block-quote {
      border-left: 4px solid rgb(189, 203, 216);
      padding-left: 24px;
    }
    
    .wp-block-embed {
      position: relative;

      .twitter-tweet {
        max-width: 100% !important;
      }

      &.is-type-video {
        .wp-block-embed__wrapper {
          padding-bottom: 56.25%; // アスペクト比 16:9の場合の縦幅
          height: 0;
          overflow: hidden;

          iframe {
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
