@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';
@import '../top/layout_sp.scss';
@import '../work/layout_pc.scss';

.Work {
  .base-title {
    font-family: Inter;
    font-weight: 300;
  }
  .section-bar {
    width: 100%;
    margin-bottom: 60px;
  }
  .mv {
    height: 400px;
    min-height: 400px;
    margin-bottom: 0;
    .mv-inner {
      height: 100%;
    }
  }
  .main-text {
    flex-direction: column;
    padding: 60px 30px 47px 30px;
    .title-box {
      display: block;
    }
    .main-title {
      width: auto;
      display: block;
      order: 1;
      font-size: 38px;
      letter-spacing: 0.95px;
      line-height: 44px;
      word-wrap: break-word;
      .sp_only {
        .text-delay {
          &_hidden {
            span {
              transform: translate(0px ,200%);
            }
          }
        }
      }
    }
    .main-title_sub  {
      order: 4;
      text-align: left;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 1.44px;
      margin-bottom: 58px;
    }
    .client_pc {
      display: none;
    }
    .client_sp {
     display: block;
     order: 3;
     color: #7990a5;
     margin-top: 30px;
     margin-bottom: 23px;
     font-size: 12px;
     letter-spacing: 1.44px;
     font-family: Inter;
     font-weight: 500;

    }
    .sub-title {
      order: 4;
      font-size: 20px;
      line-height: 35px;
      letter-spacing: 2.5px;
      width: 100%;
      position: relative;
      padding-top: 60px;
      .section-bar {
        position: absolute;
        top: 0;
        right: -30px;
        width: 345px;
      }
    }
  }
  .work-message {
    order: 5;
    width: 100%;
    margin-top: 48px;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 1.44px;
    text-align: justify;
  }
  .work-images {
    padding: 0 30px 100px 30px;
  }
  .other-works {
    padding-top: 50px;
    .section-padding {
      padding: 0 30px;
    }
    .slider {
      margin-top: 27px;
      margin-left: 30px;
    }
    .slick-slide {
      width: 285px!important;
      height: 335px;
    }
    .slick-current {
      pointer-events: auto;
    }
    .slide-item {
      height: 335px;
    }
    .work-name {
      left: 20px;
      bottom: 20px;
      width: 154px;
      font-size: 23px;
      letter-spacing: 0.58px;
      line-height: 27px;
    }
  }
}

