@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.People {
  background: $sky_blue;
  .header-left_logo {
    background: rgb(255, 255, 255);
  }
  .header {
    &-now_top {
      background: rgb(80, 104, 124);
    }
    .update_day {
      color: $black;
    }
  }
  .menu-bar {
    .bar {
      border-top: 1px solid $black;
      &::before {
        border-top: 1px solid $black;
      }
      &::after {
        border-top: 1px solid $black;
      }
    }
  }
  .people-main {
    @include padding(87, 144, 0, 144);
    h1 {
      @include font-size(69);
      @include letterSpacing(1.73);
      font-weight: bold;
      color: #0f2334;
    }
    .contents {
      @include margin(94, 0, 0, 0);
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .lazyload-wrapper {
      width: 100%;
      height: 100%;
      background: #9daebd;
      overflow: hidden;
      border-radius: 2px;
      .fadeout {
        height: 100%;
        opacity: 0;
      }
      .fadein {
        opacity: 1;
        transition: opacity 2s cubic-bezier(0.16, 1, 0.3, 1) 0.5s,transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
      }
    }
    .item {
      @include width(280);
      @include height(336);
      margin: 0 2px 4px;
      position: relative;
      &.modalAvailable {
        cursor: pointer;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .name {
        @include font-size(18);
        @include letterSpacing(0.9);
        color: $black;
        font-weight: bold;
        position: absolute;
        top: 1.59vw;
        left: 1.66vw;
      }
      .position {
        @include font-size(15);
        @include letterSpacing(0.75);
        color: #566878;
        font-weight: bold;
        position: absolute;
        top: 3.47vw;
        left: 1.66vw;
      }
    }
  }
}
