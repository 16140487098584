@import '../common/function.scss';
@import '../common/util.scss';
@import '../common/variables.scss';
@import '../sdgs/layout_pc.scss';

.Sdgs {
  .border {
    margin-right: 0px;
    width: 345px;
  }
  .first-view {
    padding: 130px 30px 119px 30px;
    h1 {
      font-size: 20px;
      letter-spacing: 2px;
      line-height: 40px;
      strong {
        .strong {
          font-size: 38px;
          letter-spacing: 1.9px;
          line-height: 40px;
        }
      }
    }
    .heading {
      margin-top: 95px;
      font-size: 15px;
      letter-spacing: 1.8px;
    }
    .text {
      margin-top: 20px;
      font-size: 12px;
      line-height: 28px;
      letter-spacing: 1.44px;
    }
  }
  .sdgs-contents {
    padding: 0 30px;
    .contents-block {
      padding: 30px 0 120px;
      .num {
        font-size: 23px;
        letter-spacing: 0.58px;
      }
    }
    .content-inner {
      padding: 0 30px;
      h2 {
        margin-top: 40px;
        font-size: 20px;
        width: 247px;
        letter-spacing: 2px;
        line-height: 35px;
      }
      .description {
        margin-top: 32px;
        font-size: 12px;
        line-height: 28px;
        letter-spacing: 1.44px;
        text-align: justify;
      }
      .action-list {
        margin-top: 40px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 1.9px;
        list-style-position: inside;
        li {
          margin-top: 20px;
          padding-left: 1em;
          text-indent: -1.1em;
          &::before {
            width: 7px;
            height: 3px;
            margin-right: 11px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}