@import 'layout_pc';

.base-title {
  font-size: 23px !important;
  font-family: Inter;
  font-weight: 300;
}
.section-padding {
  padding: 0 30px !important;
}
.pc_only {
  display: none;
}
.sp_only {
  display: block !important;
}

.Top .header {
  position: static;
}
.horizontal-bar {
  float: right;
  width: 345px !important;
}
.horizontal-bar_skyblue {
  float: right;
  width: 345px !important;
}

// header
.header {
  &-contents {
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 2;
  }
  .header-left_logo {
    width: 120px;
    height: 51px;
    img {
      width: 98px;
    }
  }
  padding: 30px 30px 0;
  .menu-bar {
    .bar {
      width: 60px;
      &::before {
        top: -11px;
      }
      &::after {
        top: 9px;
      }
      &-open {
        &::before {
          top: 0;
        }
        &::after {
          top: 0;
        }
      }
    }
  }
  .header-now_top {
    padding: 6px 2px;
    width: 60px;
    font-size: 10px;
    margin-top: 9px;
    img {
      width: 7.8px;
      height: 8px;
      margin-right: 5px;
    }
  }
  .update_day {
    width: 60px;
    letter-spacing: 0.4px;
    white-space: nowrap;
    margin-top: 9px;
    font-size: 10px;
    line-height: 1;
    &::before {
      content: '';
    }
  }
  //メニュー画面
  .menu {
    .menu-img {
      display: none;
    }
    .menu-contents {
      padding: 0 30px !important;
      width: 100%;
    }
    .contents {
      li {
        font-size: 24px;
        margin-top: 23px;
        letter-spacing: 1.15px;
      }
    }
    .info {
      letter-spacing: 0.88px;
      line-height: 24px;
      margin-top: 50px;
      font-size: 11px;
      .links {
        li {
          margin-top: 15px;
          &:not(:first-child) {
            margin-left: 7px;
          }
          span {
            margin: 0px;
            letter-spacing: -7px;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      .menu-contents {
        padding-top: 70px;
      }
    }
  }
}
.Top {
  width: 100%;
  overflow-x: hidden;

  // firstview
  .main-text {
    padding: 0 30px;
    text-align: left;
    height: auto;
    min-height: auto;
    margin-bottom: 0;
    h1 {
      font-size: 38px;
      text-align: left;
      letter-spacing: 1.2px;
      line-height: 40px;
      padding: 0;
      margin: 217px 0 24px;
    }
    p {
      display: block;
      font-size: 12px;
      margin-top: 0px;
      text-align: left;
      line-height: 20px;
      letter-spacing: 1.44px;
      white-space: nowrap;
    }
  }
  // works
  .works {
    margin-top: 133px;
    position: relative;
    .slide-center {
      width: 242px;
      height: 294px;
      img {
        width: 242px;
        height: 294px;
      }
    }
    .slide-right {
      position: absolute;
      transform: translate(0);
      right: -142px;
      width: 172px;
      height: 210px;
      img {
        width: 172px;
        height: 210px;
      }
    }
    .img-id {
      font-size: 10px;
      top: -15px;
      left: 0%;
    }
    .img-text_box {
      left: 85%;
      top: 35%;
      width: 170px;
      .title {
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 0.6px;
        margin-bottom: 10px;
        width: 100%;
      }
      .sub-title {
        font-size: 10px;
        letter-spacing: 0.5px;
        margin-bottom: 5px;
        color: rgb(207, 219, 230);
      }
      .text {
        font-size: 10px;
        letter-spacing: 0.5px;
        line-height: 14px;
        color: rgb(207, 219, 230);
      }
    }
    .sp_next_click {
      line-height: 80px;
      width: 80px;
      text-align: center;
      border-radius: 50%;
      font-size: 10px;
      letter-spacing: 1px;
      color: $sky_blue;
      border: 1px solid $sky_blue;
      position: absolute;
      bottom: -10px;
      right: -10px;
      z-index: 10;
    }
  }
  .cursor {
    width: 79px;
    height: 77px;
    font-size: 10px;
  }

  // business-summary
  .business-summary {
    padding: 120px 60px;
    h2 {
      display: block;
      font-size: 20px;
      line-height: 35px;
    }
    .text-box {
      margin-top: calc(35px *1.5);
      font-size: 12px;
      letter-spacing: 1.44px;
      text-align: justify;
      line-height: 28px;
      p {
        display: block;
        br {
          display: none;
        }
      }
      .second {
        margin-top: 2em;
      }
    }
  }

  //solution
  .solution {
    padding: 30px 29px 120px;
    .solution-inner {
      flex-direction: column;
      .left-contents {
        order: 2;
        width: 100%;
        line-height: 28px;
        font-size: 12px;
        padding: 49px 32px 0;
        text-align: justify;
        .sdgs_link {
          width: 255px;
          margin-top: 42px;
          padding: 0 24px 0 22px;
          font-size: 15px;
          line-height: 60px;
          p {
            font-size: 13px;
            margin-left: 5px;
            letter-spacing: 1.56px;
          }
          span {
            width: 12px;
            height: 12px;
            &::before {
              width: 12px;
              height: 12px;
            }
            &::after {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      .right-contents {
        margin: 0;
        margin-top: 30px;
        order: 1;
        .arrow {
          left: 20%;
          width: 265px;
          &-l {
            width: 30px;
            &_active {
              width: 100%;
            }
          }
        }
      }
      .circle-l {
        width: 319px;
        height: 319px;
        padding: 0;
        margin: 0 auto;
        &_text {
          font-size: 12px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 69%;
          line-height: 14px;
          p {
            &:not(:first-child) {
              margin-top: 15px;
            }
            span {
              font-size: 12px;
              letter-spacing: 0.2px;
            }
          }
        }
      }
      .circle-m {
        width: 208px;
        height: 208px;
        padding-right: 33px;
        &_text {
          left: 35%;
          font-size: 12px;
          color: rgb(207, 219, 230);
          letter-spacing: 0.6px;
          line-height: 14px;
          p {
            line-height: 0;
          }
        }
      }
      .circle-s {
        width: 87px;
        height: 87px;
        &_text {
          left: 8%;
          font-size: 12px;
          color: rgb(207, 219, 230);
          letter-spacing: 0.6px;
          line-height: 14px;
        }
      }
    }
    @media screen and (max-width: 320px) {
      .solution-inner .circle-l {
        width: 290px;
        height: 290px;
      }
      .solution-inner .circle-l_text {
        right: -2px;
      }
      .solution-inner .right-contents .arrow {
        right: -2px;
        &-l {
          width: 240px;
        }
      }
    }
  }

  // services
  .services {
    padding: 30px 0 119px;
    .slick-list {
      padding: 0;
    }
    .slick-initialized .slick-slide {
      width: 255px;
    }
    .slick-track {
      margin: 0;
    }
    .slick-slide {
      margin-right: 28px;
    }
    .slick-active {
      padding: 0;
    }
    .slider {
      padding: 70px 0 0 60px;
    }
    .item-title {
      font-size: 20px;
      height: 103px;
    }
    .item-text {
      font-size: 12px;
      width: 100%;
      text-align: justify;
    }
  }

  .numbers {
    padding: 30px;
    padding-bottom: 110px;
    .base-title {
      letter-spacing: 0.58px;
    }
    .contents-list {
      padding: 75px 0 0 29px;
      display: block;
      li {
        display: flex;
        &:not(:first-child) {
          margin-top: 32px;
        }
        h3 {
          font-size: 38px;
          letter-spacing: 0.95px;
          line-height: 40px;
        }
        p {
          font-size: 16px;
          margin-top: 0;
          margin-left: 20px;
          padding-top: 18px;
          letter-spacing: 0.4;
        }
      }
    }
  }

  // news
  .news {
    padding: 60px 0 55px;
    .slider {
      padding-top: 39px;
      margin-left: 29px;
    }
    .slick-slide {
      width: 285px !important;
      margin-right: 30px;
    }
    .img-inner {
      height: 176px;
      border-radius: 1px;
      img {
        border-radius: 1px;
      }
    }
    .news-date {
      font-size: 10px;
      margin-top: 24px;
      letter-spacing: 0.8px;
    }
    .news-title {
      width: 100%;
      font-size: 20px;
      margin-top: 10px;
      letter-spacing: 1.6px;
      word-break: break-all;
    }
    .view-all {
      display: none;
    }
    @media screen and (max-width: 320px) {
      .slick-slide {
        width: 20% !important;
      }
    }
  }

  .people {
    padding: 70px 0 100px;
    .slider {
      margin-left: 30px;
      padding-top: 36px;
    }
    .slick-slide {
      width: 157px !important;
    }
    .slick-active {
      margin-right: 1px;
    }
    .slide-item {
      border-radius: 1px;
      .name {
        font-size: 12px;
        top: 12px;
        left: 12px;
      }
      .job {
        font-size: 10px;
        top: 30px;
        left: 12px;
      }
      img {
        width: 100%;
        height: 185px;
      }
    }
    .slide-last {
      height: 185px;
      p {
        font-size: 12px;
        letter-spacing: 0.8px;
      }
    }
    .view-all {
      font-size: 16px;
      right: 0%;
      &::after {
        width: 12px;
        height: 12px;
        right: -21px;
      }
    }
    .people-modal {
      .modal-inner {
        display: block;
        overflow: scroll;
      }
      .modal-left {
        width: 100%;
        height: 360px;
      }
      .modal-right {
        width: 100%;
        padding: 50px 30px;
        .introduction {
          padding: 0 30px;
          .name {
            font-size: 24px;
            letter-spacing: 1.2px;
          }
          .position {
            margin-top: 11px;
            font-size: 12px;
            letter-spacing: 0.6px;
          }
          .text {
            margin-top: 45px;
            font-size: 12px;
            line-height: 28px;
            letter-spacing: 1.44px;
            text-align: justify;
          }
        }
      }
      .modal-close {
        top: 23px;
        right: 46px;
      }
      .menu-bar {
        width: 60px;
        height: 30px;
        .bar {
          width: 60px;
        }
      }
    }
    @media screen and (max-width: 320px) {
      .slick-slide {
        width: 24% !important;
      }
    }
  }

  // vision・missin
  .vision {
    margin: 0;
  }
  .overview {
    padding: 30px 30px 100px;
    .d-flex {
      display: block;
    }
    .text {
      padding-top: 0;
      font-size: 38px;
      margin-top: 40px;
      margin-left: 0;
      white-space: nowrap;
      letter-spacing: 0.95px;
      line-height: 40px;
      &_sub {
        margin-top: 17px;
        text-align: left;
        padding-left: 2px;
        line-height: 24px;
        font-size: 15px;
      }
    }
  }

  // dna
  .dna {
    margin: 0;
    .dna-contents {
      padding: 0;
      grid-gap: 52px;
      grid-column-gap: 0;
      margin-top: 40px;
      img {
        width: 106px;
      }
      h3 {
        font-size: 19px;
        margin-top: 25px;
        letter-spacing: 0.48px;
      }
      p {
        font-size: 12px;
        margin-top: 10px;
        line-height: 24px;
        letter-spacing: 1.44px;
        br {
          display: none;
        }
      }
    }
  }

  .ceo-message {
    margin-top: 30px;
    padding-bottom: 115px!important;
    .message-box {
      padding: 0;
      h3 {
        font-size: 20px;
        line-height: 35px;
        margin-top: 50px;
        letter-spacing: 2px;
      }
      p {
        margin-top: 35px;
        font-size: 12px;
        letter-spacing: 1.44px;
        line-height: 28px;
        text-align: justify;
      }
      .name {
        margin-top: 55px;
        line-height: 28px;
      }
    }
  }

  // carees
  .careers {
    .careers-inner {
      height: 500px;
      background-size: auto;
      padding-left: 60px;
    }
    .inner-texts {
      padding-top: 109px;
      display: block;
      h3 {
        font-size: 50px;
        letter-spacing: 1.25px;
      }
      p {
        letter-spacing: 0.58px;
        line-height: 28px;
        font-size: 15px;
        margin-top: 25px;
      }
      .wantedly-btn {
        width: 255px;
        height: 60px;
        margin-top: 59px;
        padding: 0 22px;
        span {
          width: 10px;
          height: 10px;
          &::before {
            width: 10px;
            height: 10px;
          }
          &::after {
            width: 9px;
            height: 9px;
            top: 0.7px;
            left: 0.5px;
          }
        }
        &:hover span::before {
          width: 500px;
          height: 500px;
          margin-top: -250px;
          margin-left: -250px;
        }
        img {
          width: 85px;
        }
      }

      .wantedly-btn-text {
        margin: 0;
        color: #0f2334;
        font-weight: bold;
        line-height: 1;
      }
    }
  }
}
// footer
.footer {
  padding: 80px 30px 36px;
  .over-review {
    font-size: 11px;
    ul {
      letter-spacing: 0.88px;
      line-height: 24px;
    }
    .privacy {
      margin-top: 24px;
    }
    .links {
      margin-top: 24px;
      p {
        span {
          margin: 0 5px;
        }
      }
    }
    div {
      display: block;
    }
  }
  .footer-logo {
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 135px;
    }
    span {
      order: 1;
      margin: 0;
      font-size: 11px;
    }
    img {
      order: 2;
      object-fit: contain;
      width: 125px;
    }
  }
}
