.text-delay {
  display: inline-table;
  overflow: hidden;
  margin: 0!important;
  .word {
    display: inline-table;
    margin: 0!important;
  }
  .char {
    display: inline-table;
    margin: 0!important;
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &_hidden {
    display: inline-table;
    overflow: hidden;
    margin: 0!important;
    line-height: 1;
    .char {
      margin: 0!important;
      opacity: 0;
      line-height: 1;
      transform: translateY(100%);
    }
  }
}