@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.works {
  position: relative;
  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  .slide-center {
    @include height(580);
    @include width(478);
    position: relative;
    img {
      @include height(580);
      @include width(478);
      object-fit: cover;
    }
  }

  .img-wrap {
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.4;
      width: 100%;
      height: 100%;
      background-color: agba(15,35,52,0);
      transition: background-color 0.3s ease-out;
    }
    img {
      transition: transform 0.3s ease-out;
      transform: scale(1);
    }
    &:hover::after {
      transition: background-color 0.3s ease-out;
      background-color: $navy;
    }
    &:hover img {
      transition: transform 0.3s ease-out;
      transform: scale(1.1);
    }
  }
  
  .slide-item {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 0%;
    overflow: hidden;
    transition: width 0.1s;
    transition-delay: 1.2s;
  }
  .is-before {
    z-index: 2;
    width: 0%;
  }
  .is-active {
    z-index: 3;
    width: 100%;
    transition: width 0.9s cubic-bezier(0.16, 1, 0.3, 1);
    transition-delay: 0.3s;
  }

  //左右の画像
  .slide-left, .slide-right {
    @include height(480);
    @include width(370);
    position: relative;
    img {
      @include height(480);
      @include width(370);
      object-fit: cover;
    }
  }
  .slide-left {
    transform: translateX(-46%);
  }
  .slide-right {
    transform: translateX(46%);
  }
  .blend-navy {
    background: linear-gradient(#d0e3f4, #051d32);
    img {
      mix-blend-mode: luminosity;
    }
  }

  //タイトル周辺
  .img-text_box {
    position: absolute;
    left: 91%;
    top: 47%;
    text-align: left;
    color: $sky_blue;
    z-index: 10;
    height: 53%;
    width: inherit;
    .img-id {
      @include font-size(12);
      display: block;
      position: absolute;
      top: -14%;
      left: 0%;
      color: $sky_blue;
      transform: translateY(-50%);
      z-index: 2;
    }
    .title {
      @include font-size(45);
      @include margin(0, 0, 25, 0);
      @include lineHeight(48);
      @include letterSpacing(1.13);
      font-family: Inter;
      word-break: keep-all;
      word-wrap: break-word;
      font-weight: bold;
      .title-text {
        .word {
          display: inline-block;
        }
        .char {
          display: inline-table;
          opacity: 1;
          transform: translateY(0%);
          transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.8s cubic-bezier(0.16, 1, 0.3, 1);
        }
      }
      .text-show {
        .word {
          display: inline-block;
        }
        .char {
          display: inline-table;
          opacity: 0;
          transform: translateY(100%);
          transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.8s cubic-bezier(0.16, 1, 0.3, 1);
        }
      }
      .text-hidden {
        .char {
          opacity: 0;
          transform: translateY(-100%);
        }
      }
    }
    .sub-title {
      @include margin(0, 0, 13, 0);
      @include font-size(12);
      @include letterSpacing(1.2);
      font-family: Inter;
      display: block;
      font-weight: 800;
      color: $sky_blue;
    }
    .text {
      @include font-size(12);
      @include lineHeight(20);
      @include letterSpacing(1.2);
      font-family: Inter;
      font-weight: 300;
      text-align: left;
      color: #7990a5;
      font-weight: 800;
    }
    .fade-out {
      opacity: 0!important;
      transition: opacity 0.8s cubic-bezier(0.16, 1, 0.3, 1)!important;
    }
    .fade-in {
      opacity: 1;
      transition: opacity 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }

  //クリックエリア
  .next-click {
    @include width(200);
    @include height(580);
    position: absolute;
    right: 0%;
    top: 0%;
    z-index: 11;
    cursor: none;
  }
  .prev-click {
    @include width(220);
    @include height(580);
    position: absolute;
    left: 0%;
    top: 0%;
    cursor: none;
    z-index: 11;
  }
}
