@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';
@import '../people/layout_pc.scss';

.People {
  .people-main {
    padding: 60px 30px 0;
    h1 {
      font-size: 38px;
      letter-spacing: 0.95px;
    }
    .contents {
      margin-top: 49px;
      justify-content: space-between;
    }
    .lazyload-wrapper {
      border-radius: 1px;
    }
    .item {
      width: 156.7px;
      height: 185px;
      margin: 0;
      margin-top: 1px;
      .name {
        font-size: 12px;
        top: 12px;
        left: 12px;
      }
      .position {
        font-size: 10px;
        top: 27px;
        left: 12px;
      }
    }
    @media screen and (max-width: 320px) {
      .item {
        width: 129px;
        height: 160px;
        margin: 0;
        margin-top: 1px;
      }
    }
  }
}
