@import '../common/function.scss';
@import '../common/variables.scss';

//メニュー画面
.menu {
  transition: height 600ms cubic-bezier(0.16, 1, 0.3, 1); //easeOutExpo
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0%;
  overflow: hidden;
  z-index: 2;
  visibility: hidden;
  &-open,
  &-close {
    transform: translateZ(0);
    backface-visibility: hidden;
    visibility: visible;
  }
  &-open {
    height: 100%;
  }
  &-close {
    height: 0;
    top: 0;
    bottom: auto;
    .menu-inner {
      top: 0;
      bottom: auto;
    }
  }
}
.menu-inner {
  height: 100vh;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.menu-img {
  width: 50%;
  height: 100vh;
  background: #9daebd;
  figure {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translate(-100px, 0);
  }
  .is-before {
    z-index: 1;
    opacity: 1;
    transform: translate(0, 0);
    transition: transform 9s linear;
  }
  .is-active {
    z-index: 2;
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity 2s, transform 9s linear;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.menu-contents {
  @include padding(0, 0, 0, 165);
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: auto;
  width: 50%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  background: #9daebd;
  z-index: 3;
  .contents {
    li {
      @include font-size(30);
      @include letterSpacing(1.5);
      display: table;
      cursor: pointer;
      color: $black;
      font-weight: bold;
      transition: color 0.2s;
      &:not(:first-child) {
        @include margin(35, 0, 0, 0);
      }
      &:hover {
        transition: color 0.2s;
        color: #50687c;
      }
    }
  }
  .info {
    @include margin(90, 0, 0, 0);
    @include font-size(12);
    @include lineHeight(24);
    @include letterSpacing(0.96);
    color: $black;
    .tel {
      span {
        text-decoration: underline;
      }
    }
    .links {
      @include margin(40, 0, 0, 0);
      display: flex;
      line-height: 1;
      li:hover {
        color: #50687c;
      }
      a {
        text-decoration: underline;
        span {
          @include margin(0, 10, 0, 10);
          display: inline-block;
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .menu-contents {
    @include padding(0, 0, 0, 0);
    justify-content: center;
    .info {
      .links {
        a {
          span {
            margin: 0 -5px;
          }
        }
      }
    }
  }
}
