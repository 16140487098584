// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
@function get_vw($size, $viewport:1440){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@function get_spacingn($top, $bottom, $left, $right, $viewport: 1440) {
  $rate: 100 / $viewport;
  @return (
    $rate * $top * 1vw
    $rate * $bottom * 1vw
    $rate * $left * 1vw
    $rate * $right * 1vw
  );
}

@mixin font-size($font_size:10) {
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

@mixin  width($width:10) {
  width: $width * 1;
  width: get_vw($width);
}

@mixin  height($height:10) {
  height: $height * 1;
  height: get_vw($height);
}

@mixin  lineHeight($lineHight:10) {
  line-height: $lineHight * 1;
  line-height: get_vw($lineHight);
}

@mixin  letterSpacing($letterSpacing:10) {
  letter-spacing: $letterSpacing * 1;
  letter-spacing: get_vw($letterSpacing);
}

@mixin padding($top:10,$bottom:10,$left:10,$right:10) {
  padding: ($top * 1, $bottom * 1, $left * 1, $right * 1);
  padding: get_spacingn($top, $bottom, $left, $right);
}

@mixin margin($top:10,$bottom:10,$left:10,$right:10) {
  margin: ($top * 1, $bottom * 1, $left * 1, $right * 1);
  margin: get_spacingn($top, $bottom, $left, $right);
}