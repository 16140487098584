@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

.Top {
  background-color: $navy;
  .header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .main-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    height: 100vh;
    min-height: 41.66vw;
    max-height: 62.5vw;
    margin-bottom: -7vw;
    .text-wrap {
      margin-top: -7vw;
    }
    h1 {
      @include font-size(69);
      @include lineHeight(72);
      @include letterSpacing(1.73);
      font-weight: bold;
      text-align: center;
      color: $sky_blue;
    }
    p {
      @include margin(45, 0, 0, 0);
      @include font-size(16);
      @include lineHeight(30);
      @include letterSpacing(1.92);
      text-align: center;
      color: #7990a5;
    }
  }

  // スライダー下のテキスト
  .business-summary {
    @include padding(223, 0, 182, 530);
    color: $sky_blue;
    h2 {
      @include font-size(27);
      @include lineHeight(48);
      @include letterSpacing(4.05);
      display: inline-block;
      text-align: left;
      width: auto;
      text-align: left;
    }
    .text-box {
      @include font-size(14);
      @include margin(55, 0, 0, 0);
      @include lineHeight(32);
      @include letterSpacing(1.68);
      .second {
        @include margin(30, 0, 0, 0);
      }
    }
  }
  .numbers {
    @include padding(44, 144, 189, 144);
    .base-title {
      color: $sky_blue;
      line-height: 1;
    }
    .contents-list {
      @include padding(75, 0, 0, 385);
      display: flex;
      justify-content: space-between;
      color: $sky_blue;
      font-weight: bold;
      font-family: Inter;
      h3 {
        @include font-size(69);
        @include letterSpacing(1.73);
      }
      p {
        @include margin(24, 0, 0, 0);
        @include font-size(21);
        @include letterSpacing(0.45);
      }
    }
  }

  .vision {
    @include padding(43, 143, 144, 143);
    .base-title {
      @include letterSpacing(0.28);
    }
    .text {
      @include padding(73, 0, 0, 0);
      @include margin(0, 0, 0, 304);
      @include lineHeight(76);
      &_sub {
        @include margin(38, 0, 0, 5);
        line-height: 1;
      }
    }
  }
  .mission {
    @include padding(43, 143, 173, 143);
    .base-title {
      @include letterSpacing(0.28);
    }
    .text {
      @include padding(80, 0, 0, 0);
      @include margin(0, 0, 0, 282);
      span {
        @include margin(43, 0, 0, 5);
      }
    }
  }

  .overview {
    .text {
      @include font-size(69);
      color: #0f2334;
      font-weight: bold;
      &_sub {
        @include font-size(21);
        @include letterSpacing(3.15);
        display: block;
        color: #6f7f8d;
      }
    }
  }
  .dna {
    @include padding(43, 143, 144, 143);
    .dna-contents {
      @include padding(113, 147, 0, 0);
      @include padding(0, 169, 0, 323);
      @include margin(94, 0, 0, 0);
      width: 100%;
      display: grid;
      grid-template-columns: 43% 43%;
      gap: 7.84vw;
      column-gap: 10.2vw;
      justify-content: space-between;
      color: $black;
      img {
        @include width(214);
        display: block;
        height: auto;
        object-fit: contain;
      }
      h3 {
        @include font-size(28);
        @include margin(47, 0, 0, 0);
        font-weight: bold;
        letter-spacing: 1.2;
      }
      p {
        @include font-size(14);
        @include margin(26, 0, 0, 0);
        @include lineHeight(28);
        @include letterSpacing(1.4);
        font-family: 'ゴシックMB101 R';
      }
    }
  }
  .ceo-message {
    @include padding(43, 143, 144, 143);
    .message-box {
      @include padding(72, 0, 0, 385);
      h3 {
        @include font-size(27);
        @include lineHeight(48);
        @include letterSpacing(3.24);
        color: $black;
      }
      p {
        @include font-size(16);
        @include margin(60, 0, 0, 0);
        @include lineHeight(36);
        @include letterSpacing(0.65);
        color: $black;
      }
      .name {
        color: #0f2334;
        text-align: right;
      }
    }
  }
  .careers {
    width: 100%;
    .careers-inner {
      @include height(720);
      width: 100%;
      background-position: center;
      background-size: contain;
      text-align: center;
    }
    .TextDelay {
      line-height: 1;
    }
    .inner-texts {
      @include padding(163, 0, 203, 0);
      display: inline-block;
      color: $sky_blue;
      text-align: left;
      h3 {
        @include font-size(81);
        @include letterSpacing(2);
        font-weight: bold;
      }
      p {
        @include margin(30, 0, 0, 0);
        @include font-size(21);
        @include letterSpacing(2.52);
        line-height: 1.71;
      }
      .wantedly-btn {
        @include width(382);
        @include margin(65, 0, 0, 0);
        @include padding(23, 21, 23, 21);
        overflow: hidden;
        position: relative;
        background: $sky_blue;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        border-radius: 2px;
        span {
          @include width(10);
          @include height(10);
          display: block;
          position: relative;
          &::before {
            content: '';
            @include width(10);
            @include height(10);
            position: absolute;
            transform-origin: right center;
            border-radius: 50%;
            display: block;
            background: #0f2334;
            opacity: 1;
            transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
          }
          &::after {
            content: '';
            @include width(10);
            @include height(10);
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0);
            border-radius: 50%;
            display: block;
            background: #0f2334;
            opacity: 0;
            transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
            transition-delay: 200ms;
          }
        }
        &:hover span::before {
          @include width(800);
          @include height(800);
          @include margin(-400, 0, 0, -400);
          transform-origin: right center;
          transition: 1s cubic-bezier(0.16, 1, 0.3, 1);
          background: #adbdcc;
          opacity: 0.5;
        }
        &:hover span::after {
          transform: scale(1);
          opacity: 1;
          transform-origin: 50% 50%;
          transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
          transition-delay: 200ms;
        }
        img {
          @include width(99);
          z-index: 3;
        }
      }

      .wantedly-btn-text {
        margin: 0;
        color: #0f2334;
        font-weight: bold;
        line-height: 1;
      }
    }
  }
}
